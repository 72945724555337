import React from "react";
import { motion } from "framer-motion";

import { ILeadershipContacts } from "~models";
import { Container, Row, Col, Divider } from "~components";

import * as style from "./LeadershipContacts.module.scss";
import { tx } from "~libs/i18n";
import { fadeInItem, staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

interface ContactProps {
  name: string;
  workTitle: string;
  number: string;
  email: string;
}

const Contact = ({ name, workTitle, number, email }: ContactProps) => {
  return (
    <motion.div variants={staggerChildrenItem} className={style.contact}>
      <p className={style.contactName} dangerouslySetInnerHTML={{ __html: name }}></p>
      <small dangerouslySetInnerHTML={{ __html: workTitle }}></small> <br />
      {number && (
        <>
          <a href={"tel:" + number} target="_blank" className={style.link}>
            {number}
          </a>
          <br />
        </>
      )}
      {email && (
        <a
          href={"mailto:" + email}
          target="_blank"
          className={`${style.link} ${style.email}`}
        >
          {tx("general.mailTo").toLocaleUpperCase()}{" "}
          {name.split(" ")[0].toLocaleUpperCase()}
          <i className={"icon-arrow-right"} />
        </a>
      )}
    </motion.div>
  );
};

interface Props {
  section: ILeadershipContacts;
}

export const LeadershipContacts = ({ section }: Props) => {
  const { sectionTitle, contacts } = section;

  return (
    <section data-sectionname="LeadershipContacts">
      <Container fluid>
        <Row>
          <Col>
            <Divider dark={true} />
          </Col>
        </Row>
      </Container>
      <Container className={style.contactHolder}>
        <Row>
          <Col>
            <motion.h4
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              className={style.title}
            >
              {sectionTitle}
            </motion.h4>
          </Col>
        </Row>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={staggerChildren}
          className="row"
        >
          {contacts.map((contact, index) => (
            <Col key={index} md={6}>
              <Contact
                name={contact.contactPerson.name}
                workTitle={contact.contactPerson.workTitle}
                number={contact.contactPerson.number}
                email={contact.contactPerson.email}
              />
            </Col>
          ))}
        </motion.div>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <Divider dark={true} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
